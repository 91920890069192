<template>
  <div class="list">
    <Header :now="4" ref="header" :page="'work'" />
    <div class="content">
      <div class="part1" id="part1">01.赛事时刻表</div>
      <div
        class="progress-bar"
        :style="{ backgroundImage: `url(${statusImg})` }"
      ></div>
      <div class="part-work">02.我的作品</div>
      <!-- <div class="more-box">
        <span @click="onShowPop(1)" class="vote">我的票数：{{ vote }}</span
        ><a href="javascript:;" class="more" @click="onShowPop(2)">小杀酒社有奖投票<span class="ic ic-arrow-right"></span></a>
      </div> -->
      <div class="no-data" v-if="!haveData">
        <div>
          <div>目前还没有提交作品哦，快去参赛吧~</div>
          <a
            href="javascript:;"
            class="ic-btn btn-in"
            @click="onJumpForm(1)"
          ></a>
        </div>
      </div>
      <div v-else>
        <!-- <div class="rank-search">
          <div class="filter-rank">
            <div :class="sort == 0 ? 'active' : ''" @click="onFilterSort(0)" class="rank-item">全部<span class="ic arrow"></span></div>
            <div :class="sort == 1 ? 'active' : ''" @click="onFilterSort(1)" class="rank-item">审核通过<span class="ic arrow"></span></div>
            <div :class="sort == 2 ? 'active' : ''" @click="onFilterSort(2)" class="rank-item">审核中<span class="ic arrow"></span></div>
            <div :class="sort == 3 ? 'active' : ''" @click="onFilterSort(3)" class="rank-item">审核未通过<span class="ic arrow"></span></div>
          </div>
        </div> -->
        <div class="list-border">
          <div class="list-r" v-if="list.length > 0">
            <div
              :class="item.type == 4 ? 'jin-nang' : 'list-item'"
              v-for="(item, index) in list"
              :key="index"
              @click="onJumpDetail(item.wid, item.status)"
            >
              <div
                class="top"
                :class="[
                  item.status < 3
                    ? 'passing'
                    : item.status === 3
                    ? 'passed'
                    : item.status === 4
                    ? 'unpass'
                    : 'finalists',
                ]"
              ></div>
              <div class="role">
                <div class="role-name">
                  <div class="country">
                    {{
                      item.type == 4
                        ? "锦"
                        : role_country.get(item.role_country)
                    }}
                  </div>
                  <div class="name">
                    {{ item.type == 4 ? "锦囊 · 望梅止渴" : item.role_name }}
                  </div>
                  <div class="blood">
                    <div v-if="item.type !== 4" class="physical">
                      <img
                        src="~/assets/images/star_on.png"
                        v-for="(flood, index) in item.blood"
                        :key="index"
                        class="xue"
                      />
                    </div>

                  </div>
                </div>
                <div class="skill">
                  <div
                    class="skill-item"
                  >
                    <div class="skill-name" v-if="item.type !== 4 ">【{{ item.key1 }}】</div>

                    <div class="skill-cont">{{ item.val1 }}</div>
                  </div>
                  <div class="skill-item" v-if="item.key2">
                    <div class="skill-name">【{{ item.key2 }}】</div>
                    <div class="skill-cont">{{ item.val2 }}</div>
                  </div>
                  <div class="skill-item" v-if="item.key3">
                    <div class="skill-name">【{{ item.key3 }}】</div>
                    <div class="skill-cont">{{ item.val3 }}</div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="ics">
                    <a
                      href="javascript:;"
                      class="ic ic-zan"
                      :class="item.vote_status == 1 ? 'ic-zaned' : ''"
                    ></a
                    >{{ item.tickets > 9999 ? "9999+" : item.tickets }}
                  </div>
                  <div class="ics">
                    <a href="javascript:;" class="ic ic-pinlun"></a
                    >{{ item.commit > 9999 ? "9999+" : item.commit }}
                  </div>
                  <div class="ics">
                    <a href="javascript:;" class="ic ic-fenxiang"></a
                    >{{ item.shares > 99 ? "99+" : item.shares }}
                  </div>
                  <div class="icons">
                    <div class="ic ic-hot" v-if="item.daily_hot > 0"></div>
                    <div class="ic ic-arrow-up" v-if="item.daily_up > 0"></div>
                    <div class="ic ic-arrow-down" v-if="item.daily_down > 0"></div>
                    <div class="ic ic-fire" v-if="item.daily_share > 0"></div>
                  </div>
                </div>
              </div>
              <!-- status 1 2 审核中 3 审核通过 4 审核未通过 5 入围 -->
              <!-- <div v-if="item.status == 1 || item.status == 2">
                <div class="status ing">
                  <div class="ic-status"></div>
                  审核中
                </div>
                <div class="fade-ing"></div>
              </div>
              <div class="status ok" v-if="item.status == 3 || item.status == 5">
                <div class="ic-status"></div>
                审核通过
              </div>
              <div v-if="item.status == 4">
                <div class="status ok">
                  <div class="ic-status"></div>
                  审核未通过
                </div>
                <div class="fade-fail"></div>
              </div> -->
            </div>
            <!-- 由父组件的pageparm单项绑定，更新子数据,callFather是子组件中参数变化触发的 -->
            <Page
              :total="pageTotal"
              :size="pageSize"
              :page="page"
              :changge="pageFn"
              v-if="pageTotal > 6"
            ></Page>
          </div>
          <div class="no-data-text" v-else>没有数据</div>
        </div>
      </div>
    </div>
    <Copyright v-show="!isChannel()" />
    <!-- 小杀酒社有奖投票 -->
    <Pop :visible="isShowPop" @hidePop="hidePop">
      <div class="pop">
        <div class="ic pop-top">
          小杀酒社有奖投票<a
            href="javascript:;"
            class="ic pop-close"
            @click="hidePop"
          ></a>
        </div>
        <div class="pop-cont">
          <div class="code"><img src="~/assets/images/code2.png" /></div>
          <div class="text-cont">
            <p>参与小杀酒社互动任务可获得更多有奖票数</p>
            <p>在小杀酒社内投票达到对应数量，即刻解锁竞猜币等道具奖励~</p>
          </div>
          <div class="tips">详情规则参看小杀酒社内活动规则</div>
        </div>
      </div>
    </Pop>
    <!-- 我的票数 -->
    <Pop :visible="isShowPopVote" @hidePop="hidePop">
      <div class="pop">
        <div class="ic pop-top">
          我的票数<a
            href="javascript:;"
            class="ic pop-close"
            @click="hidePop"
          ></a>
        </div>
        <div class="pop-cont">
          <h3 class="p-title">【图标含义如下】：</h3>
          <div class="cont-t icons">
            <div class="p-item">
              <span class="ic ic-hot"></span>作品当日获票+评论超过50个
            </div>
            <div class="p-item">
              <span class="ic ic-arrow-up"></span>当日排名上升超过20位
            </div>
            <div class="p-item">
              <span class="ic ic-arrow-down"></span>当日排名下降超过10位
            </div>
            <div class="p-item">
              <span class="ic ic-fire"></span>当日被分享超过10次
            </div>
          </div>
          <h3 class="p-title">【每位玩家初始票数为150票】：</h3>
          <div class="cont-t">
            <p>6月27日投稿&投票开启后，每位玩家可无门槛获得150票</p>
          </div>
          <div class="tips text-left">
            注：该投票计入作品人气热度，但不参与小杀酒社社区有奖活动哦
          </div>
        </div>
      </div>
    </Pop>
    <!-- 加载中... -->
    <div class="loading animate__animated animate__fadeOut" v-if="loading">
      <div>
        <div class="pic"></div>
        <div>加载中...</div>
      </div>
    </div>
    <!-- 活动未开始弹框 -->
    <Pop-tips :visible="isShowPopTips" @hidePop="hidePop" />
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "../components/content/header.vue";
import Login from "../components/content/login.vue";
import Copyright from "@/components/content/Copyright";
import Page from "@/components/common/Page.vue";
import Pop from "../components/content/pop.vue";
import { judgeWeb,isChannel } from "@/common/util";

import { reqMyWork, reqMyVote } from "@/api/http";

import PopTips from "@/components/content/PopTips";
import { config } from "@/common/const";
import { role_country } from "@/common/config";
import step1 from "@/assets/images/step1.png";
import step2 from "@/assets/images/step2.png";
import step3 from "@/assets/images/step3.png";
import step4 from "@/assets/images/step4.png";
export default {
  name: "Work",
  components: {
    Header,
    Login,
    Page,
    Copyright,
    Pop,
    PopTips,
  },
  computed: {
    statusImg: function () {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      // 确保我们关注的是2024年
      if (year > 2024) {
        return step4;
      }
      const month = currentDate.getMonth() + 1; // getMonth() 返回0-11，所以需要+1
      const day = currentDate.getDate();
      console.log(year, month, day);
      if (month < 7) {
        return step1;
        // 在7月8日之前
      } else if (month === 7) {
        if (day < 8) {
          return step1;
        } else if (day < 13) {
          return step2;
        } else if (day < 19) {
          return step3;
        } else {
          return step4;
        }
      } else {
        return step4; // 7月19日及以后
      }
    },
  },
  data() {
    return {
      agent: "pc", //是手机访问还是电脑
      token: "",
      sort: 0,
      page: 1, //当前页码
      pageSize: 6, //每页显示条目个数不传默认10
      pageTotal: 0, //总数
      list: [],isChannel,
      vote: 0,
      isShowPop: false,
      isShowPopVote: false,
      loading: false,
      haveData: false,

      isGoSubmit: false, //是否可投稿
      isShowPopTips: false, //开始投稿日期提示弹框
      timer: null, //定时器
      config,
      role_country,
    };
  },

  mounted() {
    //判断pc还是h5
    this.agent = 'pc';
    this.token = localStorage.getItem("token");
    if (this.token) {
      this.loading = true;
      this.loadMyVote();
      this.loadWorkList();
    }

    //定时开启开赛日
    this.timer = setInterval(this.funRtime, 1000);
  },
  methods: {
    //倒计时活动开始日期
    funRtime() {
      var startTime = new Date(config.startTime);
      var NowTime = new Date();
      if (NowTime >= startTime) {
        //活动开始了
        console.log("开始了work");
        this.isGoSubmit = true;
        clearInterval(this.timer);
      } else {
        console.log("没开始work");
        this.isGoSubmit = false;
      }
    },
    //选择热度
    onFilterSort(id) {
      this.sort = id;
      this.loadWorkList();
    },
    // 作品列表
    loadWorkList() {
      let { token, sort, page, pageSize } = this;
      reqMyWork({ token, status: sort, page, page_size: pageSize })
        .then((res) => {
          if (res.status === 0) {
            if (res.data.list.length > 0) {
              this.list = res.data.list;
              this.haveData = true;
            } else {
              this.list = [];
            }
            this.pageTotal = res.data.total_num;
          } else if (res.status == -1) {
            //token过期
            localStorage.clear();
            this.token = "";
            this.$refs.header.showLogin();
          } else {
            this.$toast.show(res.msg);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$toast.show("出错了");
          this.loading = false;
        });
    },
    //页码切换执行方法
    pageFn(val) {
      this.page = val;
      this.loadWorkList();
    },
    //我的投票
    loadMyVote() {
      let { token } = this;
      reqMyVote({ token })
        .then((res) => {
          if (res.status === 0) {
            this.vote = res.data.vote;
          } else if (res.status == -1) {
            this.token = "";
            localStorage.clear();
            this.$refs.header.showLogin();
          } else {
            this.$toast.show(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.show("出错了");
        });
    },
    //跳转修改//跳转详情
    onJumpDetail(wid, status) {
      if (status == 3 || status == 5) {
        this.$router.push({ path: "/detail", query: { wid } });
      } else if (status == 4) {
        this.list.filter((item) => {
          if (item.wid == wid) {
            localStorage.setItem("workdetail", JSON.stringify(item));
          }
        });
        this.$router.push({ name: "form" });
      } else {
        this.$toast.show("请耐心等待审核哦！");
      }
    },
    //跳转投稿
    onJumpForm(role) {
      if (this.isGoSubmit) {
        if (this.token) {
          localStorage.removeItem("workdetail");
          this.$router.push({ path: "/form", query: { role } });
        } else {
          this.$refs.header.showLogin();
        }
      } else {
        this.isShowPopTips = true;
      }
    },
    //显示弹框
    onShowPop(type) {
      if (type == 1) {
        this.isShowPopVote = true;
      } else {
        this.isShowPop = true;
      }
    },
    hidePop() {
      this.isShowPop = false;
      this.isShowPopVote = false;
      this.isShowPopTips = false;
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="less" scoped>
.list-border {
  width: 12.19rem;
  height: 10.51rem;
  background: url(../assets/images/content-border.png) no-repeat;
  background-size: 100% 100%;
  overflow: auto;
  .list-r {
    margin-top: 0.64rem;
    .top {
      margin-top: -0.04rem;
      width: 0.37rem;
      height: 1.52rem;
      margin-left: 0.09rem;
      margin-right: 0.09rem;
    }
    .passed {
      background: url(../assets/images/passed.png) no-repeat;
      background-size: cover;
    }
    .unpass {
      background: url(../assets/images/unpass.png) no-repeat;
      background-size: cover;
    }
    .passing {
      background: url(../assets/images/passing.png) no-repeat;
      background-size: cover;
    }
    .finalists {
      background: url(../assets/images/finalists.png) no-repeat;
      background-size: cover;
    }
    .list-item {
      margin-top: 0;
      background: url(../assets/images/my-item.png) no-repeat;
    }

    .jin-nang {
      display: flex;
      position: relative;
      width: 4.83rem;
      height: 2.86rem;
      background: url(../assets/images/my-jin.png) no-repeat left top;
      background-size: 4.83rem auto;
      cursor: pointer;
      .icons {
        display: flex;
        align-items: center;

        .ic {
          width: 0.24rem;
          height: 0.24rem;
          margin: 0 0.03rem;
        }

        .ic-hot {
          width: 0.16rem;
          background-position: -5.14rem -0.03rem;
        }

        .ic-arrow-up {
          background-position: -5.35rem -0.03rem;
        }

        .ic-arrow-down {
          background-position: -5.65rem -0.04rem;
        }

        .ic-fire {
          background-position: -5.93rem -0.03rem;
        }
      }

      .role {
        height: 2.68rem;
        padding: 0 0.4rem 0 0.25rem;
        color: #000;
        margin-top: 0.11rem;
        .role-name {
          height: 0.42rem;
          display: flex;
          width: 3.63rem;
          margin-top: 0.18rem;
          align-items: center;

          font-size: 0.22rem;
          font-weight: bold;

          .name {
            margin-right: 0.1rem;
            margin-left: 0.1rem;
            color: #416764;
          }
          .blood {
            display: flex;
            justify-content: flex-end;
            // width: 2.38rem;
          }

          .xue {
            width: 0.2rem;
            height: 0.2rem;
            margin-left: 0.02rem;
            margin-top: 0.06rem;
          }
        }
        .country {
          width: 38px;
          height: 34px;
          background: url(../assets/images/alia-country.png) no-repeat;
          background-size: cover;

          font-family: FZSHENGSKS_ZHONGJW--GB1-0;
          font-size: 19px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 34px;
          text-align: center;
          letter-spacing: 0px;
          color: #dff6f8;
        }
        .skill {
          width: 100%;
          height: 1.34rem;
          overflow-y: auto;
          margin-top: 0.1rem;

          .skill-item {
            display: flex;
            color: #070707;
            margin-bottom: 0.1rem;

            .skill-name {
              width: 0.7rem;
              text-align: left;
            }

            .skill-cont {
              flex: 1;
              text-align: left;
              padding-right: 0.03rem;
            }
          }
        }

        .bottom {
          display: flex;
          align-items: center;
          color: #4f868d;
          // justify-content: space-between;
          height: 0.61rem;
          // margin-top: 0.1rem;

          .ics {
            display: flex;
            margin-right: 0.35rem;
          }
          .ic-zan {
            width: 0.26rem !important;
            height: 0.22rem;
            background: url(../assets/images/heart2.png) no-repeat;
            background-position: 0 0 !important;
            margin-right: 0.05rem;
            &:hover {
              background: url(../assets/images/sprites.png) no-repeat;
              background-position: -5.47rem -0.64rem !important;
            }
          }
          .ic-pinlun {
            width: 0.26rem !important;
            height: 0.22rem;
            margin-right: 0.05rem;
            background: url(../assets/images/comments2.png) no-repeat;
            background-position: 0 0 !important;
            &:hover {
              background: url(../assets/images/sprites.png) no-repeat;
              background-position: -5.86rem -0.64rem !important;
            }
          }
          .ic-fenxiang {
            width: 0.26rem !important;
            height: 0.24rem;
            margin-right: 0.05rem;
            background: url(../assets/images/share2.png) no-repeat;
            background-position: 0 0 !important;
            &:hover {
              background: url(../assets/images/sprites.png) no-repeat;
              background-position: -6.22rem -0.64rem !important;
            }
          }
        }
      }

      .status {
        position: absolute;
        top: 1.6rem;
        left: -0.1rem;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.16rem;
        height: 0.62rem;
        background-color: #ffe8aa;
        font-size: 0.2rem;
        color: #000;

        &.ok {
          .ic-status {
            width: 0.24rem;
            height: 0.24rem;
            background-position: -6.64rem 0.04rem;
          }
        }

        &.fail {
          width: 0.24rem;
          height: 0.24rem;
          background-color: #6a6a6a;
          color: #fff;

          .ic-status {
            background-position: -6.9rem 0.04rem;
          }
        }

        &.ing {
          color: #fff;
          background-color: #cf2222;

          .ic-status {
            width: 0.19rem;
            height: 0.19rem;
            margin-right: 0.05rem;
            border: 0.01rem solid #fff;
            border-radius: 50%;
          }
        }
      }

      .fade-ing {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 3.1rem;
        height: 4.4rem;
        background: rgba(0, 0, 0, 0.7);
      }

      .fade-fail {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 3.1rem;
        height: 4.4rem;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
}
.icons {
  .ic {
    width: 0.24rem;
    height: 0.24rem;
    margin: 0 0.03rem;
    display: block;

    background-size: 8rem auto;
    font-size: 0;
  }

  .ic-hot {
    width: 0.16rem;
    background: url(../assets/images/hot.png) no-repeat;
  }

  .ic-arrow-up {
    background: url(../assets/images/arrow-up.png) no-repeat;
  }

  .ic-arrow-down {
    background: url(../assets/images/arrow-down.png) no-repeat;
  }

  .ic-fire {
    background: url(../assets/images/fire.png) no-repeat;
  }
}
.ics {
  .ic-zan {
    width: 0.26rem !important;
    height: 0.22rem;
    background: url(../assets/images/heart.png) no-repeat;
    background-position: 0 0 !important;
    &:hover {
      background: url(../assets/images/sprites.png) no-repeat;
      background-position: -5.47rem -0.64rem !important;
    }
  }
  .ic-pinlun {
    width: 0.26rem !important;
    height: 0.22rem;
    background: url(../assets/images/comments.png) no-repeat;
    background-position: 0 0 !important;
    &:hover {
      background: url(../assets/images/sprites.png) no-repeat;
      background-position: -5.86rem -0.64rem !important;
    }
  }
  .ic-fenxiang {
    width: 0.26rem !important;
    height: 0.24rem;
    background: url(../assets/images/share.png) no-repeat;
    background-position: 0 0 !important;
    &:hover {
      background: url(../assets/images/sprites.png) no-repeat;
      background-position: -6.22rem -0.64rem !important;
    }
  }
}
</style>
